<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                     <!-- <li class="breadcrumb-item active">Locations</li> -->
                  </ol>
               </div>
               <h4 class="page-title">Room Lists</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->

      <div class="row">
         <div class="col-lg-12">
            <div class="card">
               <div class="card-body">
                  <div class="table-responsive">
                     <table class="table mb-0">
                        <thead>
                           <tr>
                              <th>ID</th>
                              <th>Name</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="room in rooms" :key="room.id">
                              <td>{{ room.id }}</td>
                              <td>{{ room.name }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <!-- end card -->
         </div>
      </div>
   </div>
</template>

<script>
import axios from "axios";

export default {
   name: "Index",
   data() {
      return {
         rooms: [],
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   created() {
      axios.get(`${this.baseUrl}admin/v1/rooms`).then((response) => {
         this.rooms = response.data.data;
      });
   },
};
</script>

<style>
</style>